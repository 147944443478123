import React from "react";
import {Button, Grid, Paper, Typography} from "@mui/material";
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import {AlertCustomOptionsWithType} from "react-alert";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles(() => ({
    root: {
        margin: 0
    },
    alert: {
        fontSize: '18px',
        color: 'white'
    },
    alertgrid: {
        fontSize: '18px',
        width: '400px',
    },
    message: {
        fontSize: '18px'
    }

}));


const AlertBox = (props: { id: string, message: React.ReactNode, options: AlertCustomOptionsWithType, style: React.CSSProperties, close(): void }) => {
    const classes = useStyles();
    return (

        <Grid className={classes.root} container spacing={0}
              direction="row"
              alignItems="center"
              justifyContent="center"
              style={{minHeight: '75vh', pointerEvents: 'all', margin: 0}}>
            <Grid item xs={6}>
            </Grid>
            <Grid item xs={4}>
                <Paper className={classes.alertgrid} sx={{'bgcolor': '#1F242F', 'p': 2}}>
                    <Grid sx={{'mt': 0}} container spacing={2}>
                        <Grid item xs={2} sx={{p: 2}}>
                            <WarningAmberOutlinedIcon className={classes.alert} fontSize={"large"} color={'error'}/>
                        </Grid>
                        <Grid item xs={10} sx={{p: 2}}>
                            <Typography className={classes.alert}>{props.message}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent={'center'}>
                        <Button color={'primary'} variant={'contained'} onClick={props.close}>OK</Button>
                    </Grid>
                </Paper>
            </Grid>
            <Grid item xs={2}>
            </Grid>
        </Grid>
    )
}

export default AlertBox
