import {AppBar, Button, Grid, Toolbar, Typography} from "@mui/material";
import logo from '../img/hva_vu_uva_logo.svg';
import {Dispatch, SetStateAction} from "react";
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    root: {
        height: '60px',
        maxHeight: '60px',
        backgroundColor: 'white',
        // display: 'flex',
        // justifyContent: 'space-between',
    },
    logo: {
        margin: '10px',
        paddingRight: '12px'
    },
    title: {
        color: 'red',
        textAlign: 'left'
    },
    button: {
        // marginRight: 12
    }
}));

const Header = (props: {setSiteInfoState: Dispatch<SetStateAction<boolean>>}) => {

    const classes = useStyles();

    const showSiteInfo = () => {
        props.setSiteInfoState(true);
    }

    return (
        <AppBar elevation={1} position={'relative'}>
            <Toolbar className={classes.root}>
                <Grid container justifyContent="space-between" alignItems={'center'} spacing={0}>
                    <Grid item xs={4}>
                        <img className={classes.logo} src={logo} alt="Hogeschool van Amsterdam"/>
                    </Grid>
                <Grid item xs={4}>
                    <Typography className={classes.title} fontSize={11}>
                        De software van deze website is in ontwikkeling en kan fouten bevatten.</Typography>
                </Grid>
                    <Grid item xs={4}>
                        <Grid container justifyContent={'flex-end'}>
                            <Grid item sx={{ mr: 2 }}>
                                <Button color="primary" className={classes.button} onClick={showSiteInfo} variant="outlined">Uitleg over deze site</Button>
                            </Grid>
                            <Grid>
                                <Button color="primary" className={classes.button} href={"mailto:c.j.m.welie@vu.nl?Subject=Feedback DigiRevisie"} target={"_blank"}
                                        variant="outlined">Feedback</Button>

                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>
    )
}

export default Header;
