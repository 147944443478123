import React, {useState} from 'react';
import {BrowserRouter as Router, Route, Switch,} from "react-router-dom";
import './App.css';
import Header from "./component/Header";
import RevisionToolPage from "./component/RevisionToolPage/RevisionToolPage";
import {CookiesProvider} from 'react-cookie';
import {positions, Provider as AlertProvider} from "react-alert";
import AlertBox from "./component/container/AlertBox";

function App() {

    const [siteInfoState, setSiteInfoState] = useState(false);

    const alertOptions = {
        timeout: 4000,
        position: positions.TOP_CENTER,
    };

    return (
        <CookiesProvider>
            <AlertProvider template={AlertBox} {...alertOptions}>
                <Router>
                    <div className="App">
                        <Header setSiteInfoState={setSiteInfoState}/>
                        <Switch>
                            {/*<Container>*/}
                            <Route path="/">
                                <RevisionToolPage siteInfoState={siteInfoState}
                                                  setSiteInfoState={setSiteInfoState}/>
                            </Route>
                            {/*</Container>*/}
                        </Switch>
                    </div>
                </Router>
            </AlertProvider>
        </CookiesProvider>
    );
}

export default App;
