import {Button, Modal, Paper} from "@mui/material";
import {makeStyles} from '@mui/styles';
import {Dispatch, SetStateAction} from "react";

const useStyles = makeStyles(() => ({
    root: {},

    paper: {
        position: 'absolute',
        top: '10%',
        left: '10%',
        width: '80%',
        overflow: 'scroll',
        height: '80%',
        display: 'block',
        margin: '10px 10px 10px 10px'
    },
    paperInside: {
        padding: '0px 10px 10px 10px'
    },
    buttonBar: {
        width: '100%',
        textAlign: "center"
    }
}));

const SiteInfoModal = (props: { siteInfoState: boolean, setSiteInfoState: Dispatch<SetStateAction<boolean>> }) => {
    const classes = useStyles();

    let handleClose = () => {
        props.setSiteInfoState(false)
    }
    return (
        <Modal className={classes.root} open={props.siteInfoState} onClose={handleClose}>
            <Paper className={classes.paper} elevation={3}>
                <div className={classes.paperInside}>
                    <h1>Uitleg bij de site</h1>
                    <h2>Hoe werk je met de site?</h2>
                    <p>Deze site helpt je door middel van een aantal opdrachten en vragen om de samenhang van je
                        schrijfproduct te verbeteren. Het is het beste om de website
                        stap voor stap te doorlopen, maar je kunt ook met afzonderlijke stappen oefenen door erop te
                        klikken. De geschatte tijdsinvestering per stap wordt
                        aangegeven in sterren (één, twee of drie sterren).</p>
                    <h2>Hoe gebruik je de site?</h2>
                    <p>Doorloop achtereenvolgens de volgende stappen.
                        <ol>
                            <li>Plaats een Word-document in Google-drive of werk in een Google-document. Sla dit
                                document op als Google-drive document (bestand ➠ opslaan
                                als Google-document).
                            </li>
                            <li>Zorg dat je document voor iedereen toegankelijk is (optie delen ➠ link ophalen ➠
                                wijzigen ➠ iedereen op internet met deze link kan bewerken).
                            </li>
                            <li>Kopieer de weblink van je google-drive document in de balk ‘voer een google drive link
                                in’.
                            </li>
                            <li>Klik op een stap om te starten. In de linkerkolom van de website zie je een vraag en
                                (eventuele daarbij behorende hulp of tips): klik op ‘<b>controleer
                                    (nogmaals) mijn tekst</b>’ en beoordeel je tekst met behulp van de vraag. Maak
                                daarbij
                                gebruik van de hulp die de website biedt. Let op: de software
                                van de website is in ontwikkeling en kan fouten bevatten.
                            </li>
                            <li>Klik op ‘<strong>open document in google drive</strong>’ om wijzigingen aan te brengen
                                in je tekst en doe
                                dat in de Google-omgeving. Je kunt hierna weer op
                                ‘<strong>controleer mijn tekst</strong>’ klikken om de gemaakte wijzigingen te
                                beoordelen. Kik op het
                                hokje voor afgerond als je alle mogelijke wijzigingen bij een
                                vraag hebt doorgevoerd.
                            </li>
                        </ol>
                    </p>
                    <p><strong>Tip:</strong> Werk met twee schermen (één scherm voor DigiRevisie, één voor jouw tekst). Als je met één
                        scherm werkt, kun je de helft van je scherm
                        gebruiken voor DigiRevisie en de andere helft voor jouw tekst.</p>
                    <h2>Verantwoording</h2>
                    <p>De vragen op deze website zijn gebaseerd op een checklist die te vinden is in de volgende
                        publicatie:
                    </p><p>
                    <a target="_blank"
                       href='/DEF_Gefocuste Revisie_checklist voor samenhang - Camille Welie (2020).pdf'>
                        Welie, C. (2020). Gefocuste Revisie: checklist voor samenhang. Docentenhandleiding bij een
                        checklist
                        voor schrijvers om zelfstandig de samenhang van hun teksten te verbeteren. Kenniscentrum
                        Onderwijs en Opvoeding: Hogeschool van Amsterdam. </a></p>
                    <p> Met behulp van Smart Education gelden van de Hogeschool van Amsterdam
                        (<a href={'https://www.hva.nl/smarteducation'}>https://www.hva.nl/smarteducation</a>) is door
                        EDIA (<a href={'www.edia.nl'}>www.edia.nl</a>) deze checklist gedigitaliseerd en is er bij de
                        vragen op deze checklist automatische feedback gegenereerd.</p>
                    <p> Aan de totstandkoming van deze website hebben meegewerkt: Anders Bouwer, Bert Bredeweg,
                        Camille Welie, Joris Scharp, Mark Breuker, Raymond Manders en Roland Groen.</p>
                    <h2>Feedback</h2>
                    <p>We ontvangen graag je feedback op deze website. Klik <a href="mailto:c.j.m.welie@vu.nl">hier</a> om feedback te geven.</p>
                </div>
                <div className={classes.buttonBar}>
                    <Button onClick={handleClose}>Sluiten</Button>
                </div>
            </Paper>

        </Modal>
    )

}

export default SiteInfoModal
