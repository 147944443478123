const readData = function () {
    let value: string = localStorage.getItem('doneSteps') || '{}';
    if (!value.startsWith('{')) {
        value = '{}';
    }
    return JSON.parse(value);
};

const _getOrCreateLine = function (documentId: string, data: any) {
    return documentId in data ? data[documentId] as number[] : data[documentId] = [] as number[];
};

export function getDoneSteps(documentId: string) {
    return _getOrCreateLine(documentId, readData())
}

export function updateDoneSteps(documentId: string, steps: number[]) {
    const data = readData()
    data[documentId] = steps
    localStorage.setItem('doneSteps', JSON.stringify(data))
}
