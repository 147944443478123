export interface Step {
    key: string;
    title: string;
    number: number;
    description: string;
    tips: string[]
    stars: number
}

const steps: Step[] = [
    {
        key: 'noop',
        number: -1,
        title: "Uitleg",
        description: "Lees eerst de ‘uitleg over deze site’ (klik rechtsboven) voordat je gebruik maakt van deze website." ,
        tips: [],
        stars : 0
    },
    {
        key: 'noop',
        number: -1,
        title: "Voorbereidende opdrachten",
        description: "<p>Voer één van de volgende opdrachten uit:</p><ul>" +
            "<li><b>Markeeropdracht</b> <br/>" +
            "Markeer de verschillende kernideeën in je tekst met behulp van kleurenmarkers (of de\n" +
            "markeerfunctie in Word). Markeer in je tekst elk zelfde kernidee met dezelfde kleur.</li>" +
            "<li><b>Reverse Outline (steekwoorden noteren)</b> <br/> " +
            "Schrijf of typ (met opmerkingenfunctie) in de kantlijn van je tekst per alinea in steekwoorden\n" +
            "op wat het kernidee van de alinea is." +
            "</li>" +
            "</ul>" +
            "",
        tips: [],
        stars : 3
    },
    {
        key: 'has_sections',
        number: 1,
        title: "Gebruik alinea’s",
        description: "Heb je gebruik gemaakt van alinea’s in je tekst (met behulp van inspringen of een witregel)?",
        tips: ["Gebruik alinea's om de verschillende onderwerpen in je document te organiseren"],
        stars : 1
    },
    {
        key: 'long_sections',
        number: 2,
        title: "Één uniek idee per alinea",
        description: "Heeft iedere alinea slechts één uniek idee en wordt dit idee niet herhaald in de alinea?",
        tips: [
            "Zijn je alinea’s <span>opvallend lang?</span> Dit kan een indicatie zijn dat je te veel onderwerpen binnen één alinea behandelt.",
            "Zijn je alinea's <span>opvallend kort?</span> Dit kan komen doordat je één onderwerp over verschillende alinea's uitsmeert.",
            "Wordt binnen alinea's hetzelfde idee herhaald? Dan moet je tekst binnen je alinea schrappen.",
            "Zijn er verschillende alinea's die hetzelfde idee aanstippen? Dan moet je alinea's samenvoegen"],
        stars : 2
    },
    {
        key: 'noop',
        number: 3,
        title: "Volgorde van alinea’s",
        description: "Is de volgorde van je alinea’s logisch opgebouwd?",
        tips: ["Vat je tekst van de eerste tot de laatste alinea mondeling samen en gebruik daarbij ongeveer één zin per alinea (gebruik hiervoor de resultaten van je reverse outline of markeeropdracht).",
            "Is de volgorde van je alinea’s logisch als je je tekst samenvat? Of is een andere volgorde misschien beter?"],
        stars : 2
    },
    {
        key: 'noop',
        number: -1,
        title: "Opdracht: verbanden alinea’s",
        description: "<p>Om de volgende vragen goed te kunnen beantwoorden, geef je eerst in eigen woorden <b>de verbanden</b> in jouw tekst aan. Dat kan bijvoorbeeld met behulp van een ‘schema- tekstverbanden’ waarin je de alinea’s en zinnen van je tekst nummert en in steekwoorden het onderwerp per alinea en zin aangeeft. Met pijlen kun je vervolgens verbanden tussen en binnen alinea’s benoemen.</p>" +
            "<p>Kies voor een schemavorm die bij jou past en je overzicht geeft. Geef in je schema zowel verbanden tussen als binnen alinea’s aan (voorbeeld tussen alinea’s: alinea ‘x’ beschrijft de oorzaak van het probleem en alinea ‘y’ beschrijft het gevolg van het probleem; voorbeeld binnen alinea: alinea ‘x’ geeft aan wat het probleem is en somt daarna op wie er allemaal last van hebben). Geef daarna antwoord op de onderstaande vragen.</p>" ,
        tips: [],
        stars : 3
    },
    {
        key: 'signal_words_section',
        number: 4,
        title: "Verbanden tussen alinea’s",
        description: "Heb je de verbanden tussen alinea’s geëxpliciteerd met behulp van signaalwoorden of signaalzinnen?",
        tips: ["Signaalwoorden zijn woorden die het type verband tussen zinsdelen aangeven. Denk aan verschillende soorten verbanden zoals een opsomming (daarnaast, bovendien, ten eerste, ten tweede), " +
        "een tegenstelling (daarentegen, maar, echter, hoewel), een verduidelijking (bijvoorbeeld, zoals, denk hierbij aan, kortom, oftewel), een voorwaarde (indien, mits), een causaal verband (het gevolg van, daardoor, vanwege) en een chronologisch verband (gedurende, nadat, zodra).",
            "Signaalzinnen zijn zinnen die de lezer vertellen wat er achtereenvolgens in een paragraaf aan bod komt (aankondigende zinnen) of die eerdere tekst samenvatten."],
        stars : 1
    },
    {
        key: 'first_sentences',
        number: 5,
        title: "Structuur van alinea",
        description: "Zijn je alinea’s logisch opgebouwd?",
        tips: ["Denk bij een logische opbouw aan het beginnen met een kernzin, vervolgens toelichting op die kernzin en afsluiten met een concluderende zin."],
        stars : 1
    },
    {
        key: 'noop',
        number: 6,
        title: "Volgorde van zinnen (binnen alinea’s)",
        description: "Is de volgorde van je zinnen (en zinsdelen) logisch?",
        tips: ["Plaats nieuwe informatie aan het eind van een zin en start de zin eerst met bekende informatie van de vorige zin (bekend-nieuw volgorde). Als je " +
        "dit doet, komt nieuwe informatie niet uit de lucht vallen. Voorbeeld: ze droeg <b>een grote hoed (nieuwe informatie)</b>. De <b>hoed (nu bekende informatie)</b> had veel kleuren en was plat.",
            "Als je informatie van een bepaalde zin verder wil uitwerken, doe dat dan meteen in de daaropvolgende zin."],
        stars : 2
    },
    {
        key: 'signal_words',
        number: 7,
        title: "Verbanden binnen alinea’s",
        description: "Heb je de verbanden binnen alinea’s geëxpliciteerd met behulp van signaalwoorden of signaalzinnen?",
        tips: ["Signaalwoorden zijn woorden die het type verband tussen zinsdelen aangeven. Denk aan verschillende soorten verbanden zoals een opsomming (daarnaast, bovendien, ten eerste, ten tweede), " +
        "een tegenstelling (daarentegen, maar, echter, hoewel), een verduidelijking (bijvoorbeeld, zoals, denk hierbij aan, kortom, oftewel), een voorwaarde (indien, mits), een causaal verband (het gevolg van, daardoor, vanwege) en een chronologisch verband (gedurende, nadat, zodra).",
            "Signaalzinnen zijn zinnen die de lezer vertellen wat er achtereenvolgens in een paragraaf aan bod komt (aankondigende zinnen) of die eerdere tekst samenvatten."],
        stars : 2
    },
    {
        key: 'reference_words',
        number: 8,
        title: "Gebruik van verwijzingen - eenduidigheid",
        description: "Is bij verwijzingen duidelijk waarnaar of naar wie wordt verwezen?",
        tips: ["Gebruik afwisselend verschillende typen verwijzingen zoals verwijswoorden (hij, hem waar, daar, ervan, hierdoor, daarvoor) en parafrases (‘De Franse hoofdstad’ voor ‘Parijs’). " +
        "Verwijs niet met verwijswoorden naar iets wat verder dan een zin terug wordt genoemd: parafraseer of herhaal dan het concept. " +
        "Let ook op grammaticaal correct verwijzen! (Bijv. Alles <strong>wat</strong> hij zei was waar. Het boek <strong>dat</strong> ik gisteren las, etc.)"],
        stars : 1
    },
    {
        key: 'reference_candidates',
        number: 9,
        title: "Gebruik van verwijzingen – extra samenhang",
        description: "Heb je door verwijzingen te gebruiken extra samenhang in je tekst gecreëerd?",
        tips: ["Bekijk in je tekst of je meerdere keren een woord of groepje van woorden herhaalt in plaats van gebruik te maken van een\n" +
        "verwijswoord (hierdoor, daarvoor, etc.). Het gebruik van verwijswoorden vergroot de samenhang.",
            "Voorbeeld: <br/><strong>Niet zo:</strong> Het onderzoek is uitgevoerd op het Florentijncollege in Hilversum. Op het Florentijncollege in Hilversum kunnen leerlingen verschillende onderwijsniveaus volgen." +
            "<br/><strong>Maar zo:</strong> Het onderzoek is uitgevoerd op het Florentijncollege in Hilversum. Op <strong>deze school</strong> kunnen leerlingen verschillende onderwijsniveaus volgen."],
        stars : 1
    },
    {
        key: 'has_headings',
        number: 10,
        title: "Gebruik van inhoudelijke (tussen)kopjes",
        description: "Heb je gebruik gemaakt van (tussen)kopjes in je tekst en sluit de onderliggende tekst inhoudelijk aan bij je kopjes?\n",
        tips: ["Bekijk of je je paragrafen inhoudelijke titels hebt gegeven en of je tussenkopjes hebt gebruikt in je paragrafen. Bij langere paragrafen\n" +
        "kan het toevoegen van tussenkopjes extra overzicht voor de lezer creëren. Let bij het formuleren van (tussen)kopjes erop dat je inhoudelijke kopjes kiest die de tekst die eronder valt goed samenvatten. Een niet-inhoudelijk kopje is bijvoorbeeld ‘deelvraag 2’. Verwijder of verplaats tekst die niet onder het inhoudelijke kopje past óf kies een andere titel voor het kopje zodat de kopje de onderliggende tekst volledig dekt."],
        stars : 1
    },
    {
        key: 'noop',
        number: -1,
        title: "Einde",
        description: "Gefeliciteerd, je hebt alle stappen doorlopen en je bent klaar met de DigiRevisie.",
        tips: [],
        stars : 0
    }
];

export default steps;
