import {
    Box,
    Button,
    Checkbox,
    CircularProgress,
    FormControlLabel,
    FormGroup,
    Grid,
    Step,
    StepButton,
    StepContent,
    Stepper,
    Typography
} from "@mui/material";
import {Dispatch, SetStateAction, useEffect, useState} from "react";
import steps, {Step as DataStep} from "../../step_data/steps";
import {makeStyles} from '@mui/styles';
import {getDoneSteps, updateDoneSteps} from "../data/DoneSteps";

const useStyles = makeStyles(() => ({
    root: {
        backgroundColor: '#1F242F',
        height: 'calc(100vh - 60px)',
        width: '100%',
        color: 'white',
        // paddingTop: '20px',
        overflowY: 'auto',
        overflowX: 'hidden',

    },
    stepper: {
        "& .MuiStepIcon-root": { color: "#858C94" },
    },
    title: {
        color: 'lightgray',
    },
    titleActive: {
        color: 'white'

    },
    description: {
        color: 'white',
        textAlign: 'start'
    },
    backLink: {},
    infoContainer: {},
    infoGridItem: {},
    stepBoxContainer: {},
    tipsContainer: {
        // fontStyle: 'italic',
        // textAlign: 'left',
        // padding: '8px 20px 8px 0',
        // backgroundColor: 'white',
        // paddingInlineStart: '10px',
        // '& li': {
        //     marginBottom: '15px',
        //     fontStyle: 'italic'
        // },
        // display: 'inline-block'
    },
    analyseContainer: {
        // fontStyle: 'italic',
        // textAlign: 'left',
        // padding: '8px 20px 8px 0',
        // backgroundColor: 'white',
        // paddingInlineStart: '10px',
        // '& li': {
        //     marginBottom: '15px',
        //     fontStyle: 'italic'
        // },
        // display: 'inline-block'
    },
    emoticon: {
        fontStyle: 'normal',
        fontSize: '20px',
    },
    checkbox: {
        color: 'white',
    },
}));

const RevisionToolStepsViewer = (props: { step: number, setStep: Dispatch<SetStateAction<number>>, textAnalysis: string[], documentId: string, updateAnnotationState: (state: string) => void;}) => {

    const classes = useStyles();
    const stepsData = steps[props.step];
    const [doneSteps, setDoneSteps] = useState(getDoneSteps(props.documentId));

    useEffect(() => {
        props.updateAnnotationState(stepsData.key)
        localStorage.setItem('step', '' + props.step)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.step, props, stepsData.key]);

    const handleNext = () => {
        props.setStep((prevActiveStep) => {
            return prevActiveStep + 1
        })
    }
    const handleBack = () => {
        props.setStep((prevActiveStep) => {
            return prevActiveStep - 1
        })
    }

    function arrayRemove(arr: number[], value: number) {
        return arr.filter(function (item) {
            return item !== value;
        });
    }

    const finishStep = (index: number) => {
        let rv = [...doneSteps]
        const state = doneSteps.includes(index)
        if (state) {
            rv = arrayRemove(rv, index)
        } else {
            rv.push(index)
        }
        updateDoneSteps(props.documentId, rv)
        setDoneSteps(rv)
    }

    let getStars = function (step: DataStep) {
        let stars: string = ''
        if (step.stars > 0) {
            for (let x = 0; x < 3; x++) {
                stars += (x < step.stars ? '★' : '☆')
            }
        }
        return stars;
    };
    const getStepContent = function (step: DataStep, index: number) {
        const enabled = props.documentId && props.documentId !== ''
        const completed = doneSteps.includes(index)
        const active = step.number > 0
        let stars = getStars(step);
        const className = (props.step === index ? classes.titleActive : classes.title)
        return <Step key={'step-' + index} completed={doneSteps.includes(index)} disabled={!enabled}>
            <StepButton icon={active ? step.number : '•'} onClick={() => props.setStep(index)}>
                <Typography className={className}>{step.title} {stars} </Typography>
            </StepButton>
            <StepContent>
                <Box sx={{mb: 2, mr: 2, textAlign: 'left'}}>
                    <Typography className={classes.description}><span
                        dangerouslySetInnerHTML={{__html: step.description}}/></Typography>
                    {stepsData.tips.length > 0 &&
                      <Typography className={classes.description}>
                        <span className={classes.emoticon}>👉</span><span> Tips:</span>
                        <ul>
                            {stepsData.tips.map((tip, index) => (
                                <li key={'li-' + index} dangerouslySetInnerHTML={{__html: tip}}/>
                            ))}
                        </ul>
                      </Typography>}
                </Box>
                {props.textAnalysis.length === 0 && step.key !== 'noop' && <Box sx={{mb: 2, mr: 2, textAlign: 'left'}}>
                    <Typography className={classes.description}>
                        <span className={classes.emoticon}>🔬</span><span> Analyse:</span>
                    </Typography>
                    <Box sx={{textAlign: 'center'}}>
                        <CircularProgress />
                    </Box>
                </Box>}

                {props.textAnalysis.length > 0 && stepsData.number > 0 && <Box sx={{mb: 2, mr: 2, textAlign: 'left'}}>
                  <Typography className={classes.description}>
                    <span className={classes.emoticon}>🔬</span><span> Analyse:</span>
                    <ul>
                        {props.textAnalysis.map((tip, index) => (
                            <li key={'li-' + index}>{tip}</li>
                        ))}
                    </ul>
                  </Typography>
                </Box>}
                {<Box sx={{mb: 2, mr: 2, textAlign: 'left'}}>
                  <FormGroup>
                    <FormControlLabel className={classes.checkbox}
                                      control={<Checkbox color="primary" checked={completed} onChange={() => {
                                          finishStep(index)}}/>} label="Afgerond"/>
                  </FormGroup>
                </Box>}
                <Box sx={{mb: 0, mr: 2, textAlign: 'left'}}>
                    <div>
                        <Button
                            variant="contained"
                            disabled={!enabled}
                            onClick={handleNext}
                            sx={{mt: 1, mr: 1}}>
                            {index === steps.length - 1 ? 'Einde' : 'Volgende'}
                        </Button>
                        <Button
                            disabled={index === 0}
                            onClick={handleBack}
                            sx={{mt: 1, mr: 1}}>
                            Terug
                        </Button>
                    </div>
                </Box>
            </StepContent>
        </Step>;
    };
    return <Grid container spacing={0} className={classes.root} sx={{pt: 2, pb: 2}}>
        <Grid item xs={12}>
            <Typography variant="h4">DigiRevisie</Typography>
        </Grid>
        <Grid item xs={12} sx={{ml: 3}}>
            <Stepper nonLinear  className={classes.stepper} activeStep={props.step} orientation="vertical">
                {steps.map(getStepContent)}
            </Stepper>
        </Grid>
    </Grid>

}

export default RevisionToolStepsViewer;
