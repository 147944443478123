const readConsent = () => {
    return JSON.parse(localStorage.getItem('consent') || '{}')
}

const storeConsent = (consents: any) => {
    localStorage.setItem('consent', JSON.stringify(consents))
}

export const setConsent = (documentId: string, value: boolean) => {
    let consents = readConsent();
    consents[documentId] = value
    storeConsent(consents)
}

export const getConsent = (documentId: string) => {
    let consents = readConsent();
    return (documentId in consents ? consents[documentId] : null)
}


