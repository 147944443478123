import {Grid, LinearProgress} from "@mui/material";
import RevisionToolTextViewer from "./RevisionToolTextViewer";
import {Dispatch, SetStateAction, useState} from "react";
import RevisionToolStepsViewer from "./RevisionToolStepsViewer";
import steps from "../../step_data/steps";
import SiteInfoModal from "../container/SiteInfoModal";
import {makeStyles} from '@mui/styles';

const useStyles = makeStyles(() => ({
    root: {
        backgroundColor: '#F2F2F2',
        height: 'calc(100vh - 60px)',
    },
}));


const RevisionToolPage = (props: { siteInfoState: boolean, setSiteInfoState: Dispatch<SetStateAction<boolean>> }) => {

    const classes = useStyles();
    const [fetchState, setFetchState] = useState(false);
    const [textAnalysis, setTextAnalysis] = useState([] as string[]);
    let initialStep:number = parseInt(localStorage.getItem('step') || "0")
    const [annotationState, setAnnotationState] = useState(steps[initialStep].key);
    const [step, setStep] = useState(initialStep);
    const [documentId, setDocumentId] = useState(localStorage.getItem('documentId') || '');

    return (
        <Grid
            className={classes.root}
            container
            direction={'row'}>
            <Grid item>
                <SiteInfoModal siteInfoState={props.siteInfoState} setSiteInfoState={props.setSiteInfoState}/>
            </Grid>
            <Grid item xs={12}>
                {fetchState ? <LinearProgress color="primary"/> : <LinearProgress color="primary" variant="determinate" value={100}/>}
            </Grid>

            <Grid item xs={4}>
                <RevisionToolStepsViewer step={step} setStep={setStep}
                                         textAnalysis={textAnalysis}
                                         documentId={documentId}
                                         updateAnnotationState={setAnnotationState}
                                         />
            </Grid>
            <Grid item xs={8}>
                <RevisionToolTextViewer step={step}
                                        annotationState={annotationState}
                                        updateFetchState={setFetchState}
                                        setDocumentId={setDocumentId}
                                        setTextAnalysis={setTextAnalysis}/>
            </Grid>
        </Grid>
    )
}

export default RevisionToolPage;
