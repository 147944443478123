import {createTheme} from "@mui/material";

const theme = createTheme({
    palette: {
        primary: {
            main: '#28A7DF',
            contrastText: '#fff'
        }
    },
    components: {
        // Name of the component
        MuiCheckbox: {
            styleOverrides: {
                // Name of the slot
                root: {
                    // Some CSS
                    color: 'white',
                },
            },
        },
    },
})

export default theme;
