import {Button, Grid, Paper, TextField, Theme, Tooltip} from "@mui/material";
import {useEffect, useState} from "react";

import {grey, orange, pink, red, yellow} from "@mui/material/colors";
import {useAlert} from "react-alert";

import steps from "../../step_data/steps";
import {getConsent, setConsent} from "../data/Consent";
import {ContentRepresentation, DocumentRepresentation, Text} from "../data/Text";
import {makeStyles} from "@mui/styles";


const useStyles = makeStyles((theme: Theme) => ({
    root: {
        height: 'calc(100vh - 65px)',
        flexGrow: '1',
        overflow: 'auto',
        // minHeight: '100%'
    },
    section: {
        marginTop: '10px',
        marginBottom: '10px',
        textAlign: 'left'
    },
    paperBlock: {},

    defaultLow: {
        backgroundColor: grey[50],
        textDecoration: 'underline'
    },
    defaultLowest: {
        backgroundColor: grey[100],
    },
    defaultMedium: {
        backgroundColor: grey[200],
        textDecoration: 'underline'
    },
    defaultHigh: {
        backgroundColor: grey[300],
        textDecoration: 'underline'
    },
    defaultHighest: {
        backgroundColor: grey[400],
        textDecoration: 'underline'
    },
    defaultAlertA: {
        backgroundColor: orange[200],
        textDecoration: 'underline'
    },
    defaultAlertB: {
        backgroundColor: yellow[200],
        textDecoration: 'underline'
    },
    defaultAlertC: {
        backgroundColor: red[200],
        textDecoration: 'underline'
    },
    defaultAlertD: {
        backgroundColor: pink[50],
        textDecoration: 'underline'
    },
    activeCluster: {
        backgroundColor: theme.palette.primary.main,
    }
}));

// eslint-disable-next-line no-useless-escape
const PATTERN_GOOGLE_DRIVE_LINK = 'https:\/\/docs\.google.com\/document\/d\/([^/]+).*';
const RevisionToolTextViewer = (props: { annotationState: string, step: number, setDocumentId: (documentId: string) => void; updateFetchState: (state: boolean) => void; setTextAnalysis: (value: string[]) => void; }) => {

    const classes = useStyles();
    const alert = useAlert();
    const [text = [], setText] = useState([] as ContentRepresentation[])
    const [title, setTitle] = useState('')
    const [validationState, setValidationState] = useState('')
    const [activeCluster, setActiveCluster] = useState(-1)
    const [documentUrl, setDocumentUrl] = useState(localStorage.getItem('documentUrl') || '')

    const updateDocument = function () {
        // @ts-ignore
        props.setTextAnalysis([])
        if (documentUrl) {
            let documentId = extractDocumentId(documentUrl);
            props.setDocumentId(documentId || '')
            localStorage.setItem('documentId', documentId || '')
            if (documentId) {
                props.updateFetchState(true);
                const stepNumber = steps[props.step].number || -1;

                let consent = getConsent(documentId);
                if (consent == null) {
                    // show dialog.
                    const res: boolean = window.confirm(
                        "Wilt u dit inhoud van dit document delen voor onderzoeksdoeleinden?\n\n" +
                        "Deze tool is ontwikkeld voor onderzoek. Om goed onderzoek te doen is het nodig de " +
                        "veranderingen in het document te kunnen analyseren.\n\n" +
                        "Druk je op OK, dan geef je toestemming, druk je op Cancel geef je geen toestemming.")
                    setConsent(documentId, res)
                    consent = getConsent(documentId)
                }

                // @ts-ignore
                fetch(window.backendUrl + '/api/document?id=' + documentId + '&analysis=' + props.annotationState + '&step=' + stepNumber + '&store_consent=' + consent, {credentials: "include"})
                    .then(handleResponse)
                    .then((data) => {
                        const cr: DocumentRepresentation = data
                        setTitle(cr.title)
                        setText(cr.content);
                        let analysis_text: string[] = []
                        cr.analysis.forEach((analysis) => {
                            analysis_text = analysis_text.concat(analysis.analysis_text)
                        })
                        props.setTextAnalysis(analysis_text)

                    }).catch((e) => {
                    props.updateFetchState(false);
                    console.log(e)
                })
            }
        }
    };

    // @ts-ignore
    useEffect(updateDocument, [props.annotationState]);

    function handleError(error: Response) {
        const contentType = error.headers.get("content-type");
        props.updateFetchState(false);
        // alert.error("Er is een systeemfout opgetreden.", {timeout: 14000})
        const showError = (message: string) => {
            alert.error(message, {timeout: 14000})
        }
        if (contentType && contentType.indexOf("application/json") !== -1) {
            error.json().then((data) => {
                if ('error' in data) {
                    data = data['error']
                }
                showError(data['message'])
            })
        } else {
            alert.error("Er is een systeemfout opgetreden.", {timeout: 14000})
        }
    }

    function handleResponse(res: Response) {
        props.updateFetchState(false);
        if (!res.ok) {
            handleError(res)
            throw new Error('Unexpected response status');
        }
        return res.json()
    }

    const renderText = () => {
        return text.map((item: ContentRepresentation, index) => {
            if (item.elements) {
                // console.log(item.elements)
                let element = renderItem(item);
                if (element) {
                    return <div key={'div-' + index} className={classes.section}>{element}</div>
                }

            }
            return <span key={'span-' + index}/>
        });
    }

    const refreshButtonClick = () => {
        updateDocument();
    }

    function openUrl(url: string) {
        return function () {
            window.open(url, '_blank');
        };
    }

    // @ts-ignore
    const renderItem = (item: ContentRepresentation) => {
        let line = renderLine(item);
        let filtered = line.filter(function (el) {
            return el != null;
        });
        if (filtered.length > 0) {
            switch (item.tag) {
                case 'span':
                    if (item.annotation) {
                        return <Tooltip title={item.annotation}><span onClick={openUrl(item.url)}
                                                                      data-start={item.start}
                                                                      data-end={item.end}>{line}</span></Tooltip>
                    } else {
                        return <span data-start={item.start} data-end={item.end}>{line}</span>
                    }
                case 'p':
                    return <p data-start={item.start} data-end={item.end}>{line}</p>
                case 'h1':
                    return <h1 data-start={item.start} data-end={item.end}>{line}</h1>
                case 'h2':
                    return <h2 data-start={item.start} data-end={item.end}>{line}</h2>
                case 'h3':
                    return <h3 data-start={item.start} data-end={item.end}>{line}</h3>
            }

        }
    }
    let determineClassName = function (element: Text) {
        let clss = classes.paperBlock
        element.analysis.forEach(value => {
            let parts = value.split('_')
            let name = '';
            let first = true;
            parts.forEach((val) => {
                if (first) {
                    name = val;
                    first = false
                } else {
                    name += val.charAt(0).toUpperCase() + val.slice(1)
                }
            })
            if (classes.hasOwnProperty(name)) {
                // @ts-ignore
                clss = classes[name];
            } else if (value.endsWith('_lowest')) {
                clss = classes.defaultLowest
            } else if (value.endsWith('_low')) {
                clss = classes.defaultLow
            } else if (value.endsWith('_medium')) {
                clss = classes.defaultMedium
            } else if (value.endsWith('_high')) {
                clss = classes.defaultHigh
            } else if (value.endsWith('_alert_a')) {
                clss = classes.defaultAlertA
            } else if (value.endsWith('_alert_b')) {
                clss = classes.defaultAlertB
            } else if (value.endsWith('_alert_c')) {
                clss = classes.defaultAlertC
            } else if (value.endsWith('_alert_d')) {
                clss = classes.defaultAlertD
            } else {
                clss = classes.defaultHighest
            }
        });
        if (activeCluster > -1 && activeCluster === element.cluster_id) {
            return clss + ' ' + classes.activeCluster
        }
        return clss;
    }
    // @ts-ignore
    const renderLine = (item: ContentRepresentation): [] => {
        // @ts-ignore
        return item.elements.map((element: Text, index) => {
            const onMouseOver = () => {
                setActiveCluster(element.cluster_id)
            }
            // if (element.text.trim() != '') {
            let clss = determineClassName(element);
            if (element.annotation) {
                return <Tooltip title={element.annotation}>
                    <span key={'span-' + index}
                          onClick={openUrl(element.url)}
                          data-start={element.start}
                          data-end={element.end}
                          className={clss}>{element.text}</span>
                </Tooltip>
            } else {
                return <span onMouseOver={onMouseOver}
                             key={'span-' + index}
                             data-start={element.start}
                             data-end={element.end}
                             className={clss}>{element.text}</span>
            }
        })
    }


    function extractDocumentId(value: string): string | null {
        const result = new RegExp(PATTERN_GOOGLE_DRIVE_LINK, 'gm').exec(value)
        if (result && result.length > 1) {
            return result[1];
        }
        return null
    }

    function selectDocument(event: React.ChangeEvent<{ value: unknown }>) {
        const value = event.target.value
        if (typeof value === 'string' || value instanceof String) {
            const documentId = extractDocumentId(value as string)
            if (documentId) {
                setValidationState('');
            } else {
                setValidationState('error');
            }
            setDocumentUrl(value as string)
            localStorage.setItem('documentUrl', value as string)
        }

    }


    return (
        <div className={classes.root}>
            <Grid container

                  spacing={2}
                  sx={{pl: 2, pr: 2}}
                  direction={'row'}
                  alignItems={'baseline'}>
                <Grid item xs={12}>
                    <TextField sx={{mt: 2}} fullWidth
                               size={"medium"}
                               error={validationState === 'error'}
                               variant={"outlined"}
                               id="documentInput"
                               label={'Voer een google drive link in'}
                               onChange={selectDocument}
                               inputProps={{pattern: PATTERN_GOOGLE_DRIVE_LINK}}
                               value={documentUrl}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Grid container justifyContent="space-between">
                        <Button color={'primary'} variant={'contained'} onClick={refreshButtonClick}>
                            {text.length > 0 ? "Controleer nogmaals mijn tekst" : "Controleer mijn tekst"}
                        </Button>
                        {validationState !== 'error' &&
                          <Button target={'_blank'} href={documentUrl}>Open document in google drive</Button>

                        }
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Paper elevation={6} sx={{p: 1}}>
                        <h1>{title}</h1>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper elevation={6} sx={{p: 3, mb: 2}}>
                        {renderText()}
                    </Paper>
                </Grid>
            </Grid>
        </div>
    )
}

export default RevisionToolTextViewer;
